import React, { useState, useEffect } from "react";

const WidgetTopBanner = ({ data: { slides, delay } }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    if (slides.length > 1) {
      const interval = setInterval(() => {
        setCurrentSlide((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
      }, delay);
      return () => clearInterval(interval);
    }
  }, [slides.length, delay]);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
  };

  return (
    <div className="banner-slider">
      <div className="banner-slider__container">
        {slides.map((slide, index) => (
          <div
            className={`banner-slider__slide ${
              index === currentSlide ? "active" : ""
            }`}
            style={{
              color: slide.color ?? "white",
              backgroundColor: slide.backgroundColor ?? "black",
            }}
            dangerouslySetInnerHTML={{ __html: slide.content }}
            key={index}
          ></div>
        ))}
      </div>
      {slides.length > 1 && (
        <>
          <button className="banner-slider__arrow left" onClick={prevSlide}>
            &#10094;
          </button>
          <button className="banner-slider__arrow right" onClick={nextSlide}>
            &#10095;
          </button>
        </>
      )}
    </div>
  );
};

export default WidgetTopBanner;
